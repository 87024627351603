import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/aftersales/insentif',
        component: () => import('@/layout/AfterSalesIncentiveLayout.vue'),
        redirect: '/aftersales/insentif/perhitungan-po',
        children: [
            {
                path: 'perhitungan-po',
                name: 'AfterSalesIncentiveCalculationPOView',
                component: () => import('@/views/AfterSalesIncentiveCalculationPOView.vue'),
            },
            {
                path: 'insentif-sales',
                redirect: '/aftersales/insentif/insentif-sales/account-manager',
                children: [
                    {
                        path: ':incentiveSalesPath',
                        name: 'AfterSalesIncentiveSalesView',
                        component: () => import('@/views/AfterSalesIncentiveSalesView.vue'),
                    },
                ],
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
